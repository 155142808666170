import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  DailyVideo,
  useDailyEvent,
  useDevices,
  useLocalSessionId,
  useParticipantProperty,
} from '@daily-co/daily-react';
import { useCallback, useEffect, useState } from 'react';
import UserMediaError from '../UserMediaError/UserMediaError';

import './HairCheck.css';
import { SearchParams } from "@/main";
import { DEFAULT_CYCLE } from "@/consts";
import { useSearchParams } from "react-router-dom";

export default function HairCheck({ joinCall }: { joinCall: any }) {
  const localSessionId = useLocalSessionId();
  const initialUsername = useParticipantProperty(localSessionId, 'user_name');
  const { currentCam, currentMic, currentSpeaker, microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  const [username, setUsername] = useState(initialUsername);

  const [getUserMediaError, setGetUserMediaError] = useState(false);

  const [searchParams] = useSearchParams();
  const cycleNumber = searchParams.get(SearchParams.Cycle) ?? DEFAULT_CYCLE;

  useEffect(() => {
    setUsername(initialUsername);
  }, [initialUsername]);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  const handleJoin = (e: any) => {
    e.preventDefault();
    joinCall(username.trim(), cycleNumber);
  };

  const updateMicrophone = (e: any) => {
    setMicrophone(e);
  };

  const updateSpeakers = (e: any) => {
    setSpeaker(e);
  };

  const updateCamera = (e: any) => {
    setCamera(e);
  };


  return getUserMediaError ? (
    <UserMediaError />
  ) : (
    <form className="hair-check" onSubmit={handleJoin}>
      <h1>Preview</h1>
      {/* Video preview */}
      {localSessionId && <DailyVideo sessionId={localSessionId} mirror type={"video"} />}

      {/* Microphone select */}
      <div>
        <Label htmlFor="micOptions">Microphone:</Label>
        <Select name="micOptions" onValueChange={updateMicrophone} value={currentMic?.device?.deviceId}>
          <SelectTrigger className="w-[325px]">
            <SelectValue placeholder="Select a microphone" />
          </SelectTrigger>
          <SelectContent>
            {microphones.map((mic) => (
              <SelectItem key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                {mic.device.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Speakers select */}
      <div>
        <Label htmlFor="speakersOptions">Speakers:</Label>
        <Select name="speakersOptions" onValueChange={updateSpeakers} value={currentSpeaker?.device?.deviceId}>
          <SelectTrigger className="w-[325px]">
            <SelectValue placeholder="Select a speaker" />
          </SelectTrigger>
          <SelectContent>
            {speakers.map((speaker) => (
              <SelectItem key={`speaker-${speaker.device.deviceId}`} value={speaker.device.deviceId}>
                {speaker.device.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {/* Camera select */}
      <div>
        <Label htmlFor="cameraOptions">Camera:</Label>
        <Select name="cameraOptions" onValueChange={updateCamera} value={currentCam?.device?.deviceId}>
          <SelectTrigger className="w-[325px]">
            <SelectValue placeholder="Select a camera" />
          </SelectTrigger>
          <SelectContent>
            {cameras.map((camera) => (
              <SelectItem key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
                {camera.device.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <Button variant="outline" onClick={handleJoin} type="submit" className="m-4 bg-blue-700 text-white">
        Start
      </Button>

      <p className="  bg-blue-400  text-white p-2 rounded-md">
        Note: After clicking start you will be asked to share your screen. Click on the "Window" tab then "Once" then "Share" to begin.
      </p>
    </form>
  );
}
