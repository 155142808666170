import { Suspense, useEffect } from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes, redirect, useLocation, useNavigationType
} from "react-router-dom";
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { authProvider } from "./auth";
import Login from "./components/login";
import MagicLinked from "./components/magic_linked";
import Menu from "./components/menu";
import Session, { sessionAction, sessionLoader } from "./pages/session";
import StudentData from "./pages/student-data";

import './index.css';
import ErrorPage from "./components/error-page";
import SessionFinished from "./components/SessionFinished";
import MyRecordings from "./pages/my-recordings";
import Curriculum from "./pages/curriculum";
import InstructionalLog from "./pages/instructional-log";
import InstructorObjectives from "./pages/instructor-objectives";

Sentry.init({
  dsn: "https://d7a8d65ea550a44bd27f02bece80ecba@o4507172268867584.ingest.us.sentry.io/4507188034076672",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: import.meta.env.MODE,
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export enum SearchParams {
  Cycle = "cycle",
  StudentId = "student_id",
  JWT = "jwt",
}

export enum LocalStorageParams {
  Cycle = "CYCLE",
  StudentId = "STUDENT_ID",
  JWT = "JWT",
}

// If the user is not logged in and tries to access a protected endpoint, we redirect
// them to `/login
const searchParams = new URLSearchParams(location.search);
const hasStudentId = searchParams.has(SearchParams.StudentId);
console.log(hasStudentId)

const Loader = () => <div>Loading</div>;
const routes: RouteObject[] = [
  {
    id: "root",
    path: "/",
    loader: protectedLoader,
    element: <Menu />,
    children: [
      {
        element: <Session />,
        errorElement: <ErrorPage />,
        loader: sessionLoader,
        action: sessionAction,
        shouldRevalidate: () => false,
        // If the user has a student id in the url, 
        // we make "/" the index route
        index: hasStudentId,
      },
      {

        path: "/instructional-log",
        element: <InstructionalLog />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/student-data",
        element: <StudentData />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/my-recordings",
        element: <MyRecordings />,
        errorElement: <ErrorPage />,
        shouldRevalidate: () => false

      },
      {
        path: "session-finished",
        Component: SessionFinished,
      },
      {
        path: "instructor-objectives",
        Component: InstructorObjectives,
      },
      {
        path: "curriculum",
        Component: Curriculum,
      },
      { path: "curriculum/:cycle", Component: Curriculum },
    ]
  },
  {
    path: "login",
    Component: Login,
  },
  {
    path: "magic_linked",
    Component: MagicLinked,
  }
]

const router = sentryCreateBrowserRouter(routes);

/**
 * Returns a function that can be used as a loader for a route in the router.
 * This function ensures that the user is authenticated before rendering the route,
 * and if the user is not authenticated, it redirects them to the login page.
 */
async function protectedLoader() {
  await authProvider.signin();


  if (!authProvider.isAuthenticated) {
    return redirect("/login?" + searchParams.toString());
  }

  return authProvider;
}

const queryClient = new QueryClient();

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID
  })

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <LDProvider>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loader />}>
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
    </LDProvider>
  );
})();
