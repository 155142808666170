import { useEffect, useRef, useState } from "react";
import CycleChooser from "@/components/CycleChooser";
import { Button } from "@/components/ui/button";
import { Cycle, useCycles } from "@/hooks/useCycles";
import { constructUrl } from "@/lib/constructUrl";
import { Icons } from "@/components/icons";
import { findValidCycle } from "@/lib/findValidCycle";
import { useParams } from "react-router";
import { useFlags } from "launchdarkly-react-client-sdk";

type CurriculumProps = {
  cycle: Cycle | null,
  isLoading: boolean
}

function Curriculum() {
  const { curriculumUrls } = useFlags();
  const { cycle } = useParams();
  const { data: cycles = [] } = useCycles();
  const paramCycle = curriculumUrls ? cycles?.find(c => c.cycle === cycle) : null;

  const [state, setState] = useState<CurriculumProps>({
    cycle: paramCycle ?? null,
    isLoading: Boolean(paramCycle)
  });
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // We need this to ensure that the state gets updated when the url has a curriculum cycle specified.
  useEffect(() => {
    if (curriculumUrls) {
      setState(prev => ({
        ...prev,
        cycle: paramCycle ?? null,
        isLoading: Boolean(paramCycle)
      }))
    }
  }, [paramCycle, curriculumUrls])

  function setCycle(cycleId: string) {
    const validCycleId = findValidCycle(cycleId, cycles);
    const cycle = cycles.find(c => c.cycle === validCycleId)
    setState(prev => ({
      ...prev,
      cycle: cycle ?? null,
      isLoading: true
    }));
    if (curriculumUrls) {
      window.history.replaceState({}, "", `/curriculum/${validCycleId}`)
    }
  }

  function enterFullScreen() {
    if (iframeRef.current) {
      iframeRef.current.requestFullscreen()
    }
  }

  function setLoading(isLoading: boolean) {
    setState(prev => ({
      ...prev,
      isLoading
    }))
  }

  return (
    <div className="px-4 py-2 flex flex-col w-full">
      <div className="text-lg mb-4 flex items-center gap-2">
        Cycle
        <CycleChooser
          cycles={cycles}
          onChange={setCycle}
          value={state.cycle?.cycle ?? null}
        />
        {
          state.cycle &&
          <Button onClick={enterFullScreen} variant="default">
            Enter full screen
          </Button>
        }
        {
          state.isLoading &&
          <div>
            Loading Cycle... <Icons.spinner className="h-4 w-4 animate-spin inline-block align-baseline" />
          </div>
        }
      </div>
      {
        state.cycle?.google_file_id && <iframe
          ref={iframeRef}
          src={constructUrl(
            state.cycle.google_file_id,
            false,
            100000,
            true,
            state.cycle.slide_number
          )}
          onLoad={() => setLoading(false)}
          allowFullScreen={true}
          className="flex-1 w-full"
        />
      }
    </div>
  );
}

export default Curriculum;