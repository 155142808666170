import Axios from "axios";

export type AuthProvider = AuthUser & {
  signin(): Promise<void>;
};

type AuthUser = {
  isAuthenticated: boolean;
  username: string | null;
  email: string | null;
  pictureUrl: string | null;
  instructionalLogUrl: string | null;
  userClassification: UserClassification | null;
  studentDataEmbedId: string | null;
};

type UserClassification =
  | "Once Employee (No PII)"
  | "Once Coach Manager"
  | "District or School-based Staff"
  | "Once Evaluator"
  | "Once Coach"
  | "Once Employee"
  | "Direct to Consumer Customer";

export const authProvider: AuthProvider = {
  isAuthenticated: false,
  username: null,
  pictureUrl: null,
  email: null,
  instructionalLogUrl: null,
  userClassification: null,
  studentDataEmbedId: null,
  async signin() {
    let result = new Promise<AuthUser>(function (resolve) {
      if (localStorage.getItem("JWT") == null) {
        console.log("No JWT found");
        resolve({
          isAuthenticated: false,
          username: null,
          email: null,
          pictureUrl: null,
          instructionalLogUrl: null,
          userClassification: null,
          studentDataEmbedId: null,
        });
      } else {
        console.log("JWT found");
        Axios.get(`${import.meta.env.VITE_BACKEND_URL}/me`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
          },
        })
          .then((res) => {
            resolve({
              isAuthenticated: true,
              username: res.data.name || null,
              pictureUrl: res.data.picture || null,
              email: res.data.email || null,
              instructionalLogUrl: res.data.instructional_log || null,
              userClassification: res.data.user_classification || null,
              studentDataEmbedId: res.data.student_data_embed_id || null
            });
          })
          .catch((err) => {
            console.log("Error while checking jwt: " + err);
            localStorage.removeItem("JWT");
            resolve({
              isAuthenticated: false,
              username: null,
              pictureUrl: null,
              email: null,
              instructionalLogUrl: null,
              userClassification: null,
              studentDataEmbedId: null,
            });
          });
      }
    });
    let credentials: AuthUser = await result;
    authProvider.isAuthenticated = credentials.isAuthenticated;
    authProvider.username = credentials.username;
    authProvider.pictureUrl = credentials.pictureUrl;
    authProvider.email = credentials.email;
    authProvider.instructionalLogUrl = credentials.instructionalLogUrl;
    authProvider.userClassification = credentials.userClassification;
    authProvider.studentDataEmbedId = credentials.studentDataEmbedId;
  },
};
