
import Select from 'react-select'
import { Cycle } from '@/hooks/useCycles';


const CycleChooser = (props: {
  cycles: Cycle[],
  onChange: (cycle: string) => void,
  value: string | null
}) => {
  const options = props.cycles.map((cycle) => {
    return { value: cycle.cycle, label: cycle.cycle }
  })

  return <Select
    value={props.value ? {
      value: props.value,
      label: props.value,
    } : {
      value: null,
      label: "Choose a cycle"
    }}
    options={options}
    styles={{
      control: (styles) => ({
        ...styles,
        width: 300,
      }),
      option: (styles) => ({
        ...styles,
        color: "black"
      })
    }}
    onChange={value => {
      if (!value?.value) return
      props.onChange(value.value)
    }}
  />

}
export default CycleChooser