import { Cycle } from "@/hooks/useCycles";

const defaultCycle = "0.0";

/**
 * @param cycleKey - The input cycle key to be validated.
 * @param cycleMap - The map containing valid cycle keys and their corresponding values.
 *
 * @returns A valid cycle key as a string. If the input cycle key is not valid, it will return the closest valid cycle key.
 */
/**
 * Returns a valid cycle key based on the input cycle key and cycle map.
 *
 * @param cycleId - The input cycle key to be validated.
 * @param cycles - The map containing valid cycle keys and their corresponding values.
 *
 * @returns A valid cycle key as a string. If the input cycle key is not valid, it will return the closest valid cycle key or the default "1.1"
 */
export function findValidCycle(
  cycleId: string | null,
  cycles: Cycle[]
): string {
  // Convert the cycle key to a number and trim to one decimal point.
  // e.g. "2.222222" -> "2.2" and "2" -> "2.0"
  const roundedCycleId = Number(cycleId).toFixed(1);

  // Find a matching cycle
  const validCycle = cycles.find((cycle) => cycle.cycle === roundedCycleId);

  if (validCycle) {
    return validCycle.cycle;
  }

  // group cycles by whole number so we can find the closest valid cycle
  const groups = cycles
    .map((c) => c.cycle)
    .map(Number)
    .reduce(byLesson, {});

  // For invalid cycles try to find a close match. "2.7" which is invalid would return "2.6"
  const groupNumber = Math.floor(Number(roundedCycleId));
  const group = groups[groupNumber];
  if (group == null) {
    return defaultCycle;
  }

  const potentialCycleId = group && group[group.length - 1];
  if (potentialCycleId == null) {
    return defaultCycle;
  }

  return potentialCycleId.toString() ?? defaultCycle;
}

/**
 * Groups cycles by whole number so we can find the closest valid cycle.
 *
 * @param groups - An object containing an array of cycle numbers grouped by their whole number part.
 * @param key - The current cycle number to be grouped.
 *
 * @returns An object containing an array of cycle numbers grouped by their whole number part.
 */
function byLesson(
  groups: {
    [key: string]: number[];
  },
  key: number
): {
  [key: string]: number[];
} {
  const group = Math.floor(key);
  if (!groups[group]) {
    groups[group] = [];
  }
  groups[group].push(key);
  return groups;
}
