import Axios from "axios";
import { authProvider } from "../auth";
import { useQuery } from "@tanstack/react-query";

export type Recording = {
  cycle_number: string;
  district_name: string | null;
  email: string;
  id: number;
  instructor_name: string | null;
  recording_url: string | null;
  room_name: string | null;
  school_name: string | null;
  student_id: string;
  student_name: string | null;
  timestamp: string | null;
};

const QUERY_KEY = ["Recordings"];

const fetchRecordings = async (): Promise<Recording[]> => {
  const { data } = await Axios.get<Recording[]>(
    `${import.meta.env.VITE_BACKEND_URL}/session?email=${authProvider.email}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("JWT")}`,
      },
    }
  );
  return data;
};

export const useRecordings = () => {
  return useQuery<Recording[], Error>({
    queryKey: QUERY_KEY,
    queryFn: fetchRecordings,
  });
};
