/**
 * Generates iframe compatible url to display the presentation
 * @param presentationKey The Google Slides presentation key
 * @param loop Boolean for whether the slides should loop after finishing
 * @param slideDuration Duration in seconds for how long each slide should be
 * @param showControls Boolean for whether to display the Google Slides controls
 */
export const constructUrl = (
  presentationKey: string | null,
  loop: boolean,
  slideDuration: number,
  showControls: boolean,
  position: number
): string => {
  let baseUrl = "https://docs.google.com/presentation/d/e/";
  baseUrl += `${presentationKey}/embed?`;
  baseUrl += `loop=${loop ? "true" : "false"}`;

  // If slide duration given, add it
  if (slideDuration) {
    baseUrl += `&start=true`;
    baseUrl += `&delayms=${slideDuration * 1000}`;
  }

  if (!showControls) {
    baseUrl += `&rm=minimal`;
  }

  if (position) {
    baseUrl += `&slide=${position}`;
  }
  return baseUrl;
};
