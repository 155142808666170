import Axios from "axios";
import { authProvider } from "../auth";
import { useEffect, useState } from "react";
import { Icons } from "@/components/icons";

function InstructorObjectives() {
  const [jwt, setJwt] = useState<string | null>(null);

  useEffect(() => {
    async function fetchExploJwt() {
      const body = {
        provided_id: authProvider.email,
        embed_id: "L9YmnejxXl",
      };

      const results = await Axios.post(
        `${import.meta.env.VITE_BACKEND_URL}/api/generate_explo_jwt`,
        body,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("JWT")}`,
          },
        }
      );
      setJwt(results.data.jwt);
    }
    fetchExploJwt();
  }, []);

  if (!jwt) {
    return <Icons.spinner className="m-auto h-4 w-4 animate-spin" />;
  }

  return (
    <div className="w-full">
      <explo-dashboard
        dash-jwt={jwt}
        isProduction={true}
        environment="production"
        refresh-minutes={10}
        style={{ height: "100vh", width: "100vw" }}
      ></explo-dashboard>
    </div>
  );
}

export default InstructorObjectives;

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "explo-dashboard": any;
    }
  }
}
