import useClickOutside from "@/hooks/useClickOutside";
import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";

type ButtonProps = {
    enabled: boolean;
    onClick: () => void;
}

type PopupProps = React.PropsWithChildren<{
    show: boolean;
}>;

const CoachAiButton = React.forwardRef<HTMLDivElement, ButtonProps>((props: ButtonProps, ref) => {
    const { onClick } = props;
    return (
        <div ref={ref} className="ml-auto pr-10 flex items-center gap-2" role="button" onClick={onClick}>Coach<strong>AI</strong><CoachAiIcon /></div>
    )
});

const CoachPopup = React.forwardRef<HTMLDivElement, PopupProps>(({ children, show }: PopupProps, containerRef) => {

    return (
        <div ref={containerRef} className={"relative transition-all mt-3" + (show ? " z-50 opacity-100" : " -z-50 opacity-0")}>
            <div className="absolute top-8 right-0">
                {children}
            </div>
        </div>
    )
});

function CoachAiIcon() {
    return (<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.14454 4.50512C5.93637 4.0092 5.15383 4.0092 4.94566 4.50512C4.51352 5.53553 3.9003 6.80176 3.28732 7.40873C2.67049 8.01943 1.44226 8.63016 0.454931 9.05853C-0.031621 9.26965 -0.031621 10.0183 0.454931 10.2294C1.44226 10.6578 2.67049 11.2684 3.28732 11.8792C3.9003 12.4861 4.51352 13.7524 4.94566 14.7828C5.15383 15.2787 5.93637 15.2787 6.14454 14.7828C6.57667 13.7524 7.18989 12.4861 7.80288 11.8792C8.41609 11.2722 9.69512 10.6653 10.736 10.2373C11.2367 10.0314 11.2367 9.25662 10.736 9.05062C9.69512 8.62267 8.41609 8.01568 7.80288 7.40873C7.18989 6.80176 6.57667 5.53553 6.14454 4.50512Z" fill="#548ce4" />
        <path d="M15.1887 0.384166C14.9511 -0.128055 14.117 -0.128055 13.8794 0.384166C13.6096 0.965687 13.2844 1.55592 12.9591 1.87913C12.6295 2.20667 12.0497 2.53418 11.4869 2.80434C10.9864 3.04462 10.9864 3.8439 11.4869 4.08416C12.0498 4.35429 12.6295 4.6818 12.9591 5.00932C13.2844 5.33253 13.6096 5.92276 13.8794 6.50429C14.117 7.01651 14.9511 7.01651 15.1887 6.50429C15.4585 5.92276 15.7838 5.33253 16.109 5.00932C16.4343 4.6861 17.0282 4.36288 17.6134 4.09481C18.1289 3.85869 18.1289 3.02981 17.6134 2.79369C17.0282 2.52559 16.4343 2.20235 16.109 1.87913C15.7838 1.55592 15.4585 0.965687 15.1887 0.384166Z" fill="#548ce4" />
    </svg>
    )
}

function UnderstoodAI() {
    const { coachAiEnabled } = useFlags();
    const [isOpen, setIsOpen] = React.useState(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const triggerRef = React.useRef<HTMLDivElement>(null);
    useClickOutside(isOpen, containerRef, () => { setIsOpen(false); }, triggerRef);

    if (!coachAiEnabled) {
        return <div className="ml-auto"></div>
    }

    return (<>
        <CoachAiButton ref={triggerRef} enabled={!isOpen} onClick={() => { setIsOpen((old) => !old); }} />
        <CoachPopup ref={containerRef} show={isOpen}>
            <iframe
                width="375px"
                height="600px"
                src="https://once.assistant.understood.org/"
                title="TryOnce Assistant"
                className="rounded-sm shadow-lg"
            />
        </CoachPopup >
    </>)
}

export default UnderstoodAI;