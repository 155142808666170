export default function searchParamsToObject(search: string) {
  // if no search params return a blank object
  if (!search) return {};
  return JSON.parse(
    '{"' +
      decodeURI(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}
