import React, { useEffect, useRef } from 'react';
import { Button } from "@/components/ui/button";
import { useScreenShare } from '@daily-co/daily-react';
import useLocalTime from '@/hooks/useLocalTime';
import { constructUrl } from '../lib/constructUrl';
import { useToast } from './ui/use-toast';

interface BaseReactGoogleSlidesProps {
  slidesLink: string;
  leaveCall: any;
  isFullScreen: boolean;
  setIsFullScreen: (isFullScreen: boolean) => void;
  loop?: boolean;
  slideDuration?: number;
  showControls?: boolean;
  position?: number;
  containerStyle?: object | null;
  ErrorComponent?: React.ReactNode | React.ElementType;
}

export type ReactGoogleSlidesProps = BaseReactGoogleSlidesProps &
  React.HTMLProps<HTMLIFrameElement>;

export const Content: React.FC<ReactGoogleSlidesProps> = ({
  slidesLink,
  leaveCall,
  loop = false,
  slideDuration = 100000, // default slide duration in seconds (we do not want to auto-advance but a value is required)
  showControls = true,
  position = 0,
  containerStyle = null,
  isFullScreen,
  setIsFullScreen,
  ErrorComponent,
  ...props
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const presentationKey = slidesLink;
  const url = constructUrl(
    presentationKey,
    loop,
    slideDuration,
    showControls,
    position
  );

  const { isSharingScreen, screens } = useScreenShare();
  const { time } = useLocalTime();
  const { toast } = useToast()

  if (!presentationKey && ErrorComponent) {
    return <>{ErrorComponent}</>;
  }

  const handleExitButtonClick = () => {
    leaveCall();
  }

  function handleGoToFullScreen() {
    if (iframeRef?.current && isSharingScreen && screens.length) {
      try {
        iframeRef?.current?.requestFullscreen()
      } catch (error) {
        toast({
          title: "Error",
          description: "Could not enter full screen",
        })
      }
    }
  }

  /**
   * Enter fullscreen once sharing begins
   */
  useEffect(() => {
    handleGoToFullScreen()
  }, [isSharingScreen])

  /**
   * Tracks fullscreen state after a document fullscreen event. We use this
   * to show or hide various buttons to the user - depending on full screen state
   */
  useEffect(() => {
    // set fullscreen boolean on fullscreen event
    function onFullScreenEvent() {
      if (document.fullscreenElement) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    }

    // listen for fullscreen event
    if (iframeRef?.current) {
      iframeRef?.current?.addEventListener("fullscreenchange", onFullScreenEvent)
    }

    return () => {
      if (iframeRef?.current) {
        iframeRef?.current?.removeEventListener("fullscreenchange", onFullScreenEvent)
      }
    }
  })

  return (
    <div ref={iframeRef} className="w-full flex flex-col" style={{
      height: "calc(100vh - 124px)", // screen hight minus header
    }}>
      {
        !isFullScreen &&
        <div className="p-2 self-center">
          <Button onClick={handleGoToFullScreen} variant="default">
            Enter full screen
          </Button>
        </div>
      }
      <iframe
        src={url}
        style={containerStyle ? containerStyle : { border: 0 }}
        allowFullScreen={true}
        className='flex-1'
        {...props}
      />

      {
        isFullScreen &&
        <div className="absolute right-2 bottom-1.5">
          <span className="p-4">
            {time}
          </span>
          <Button onClick={handleExitButtonClick} variant="destructive" className="w-36 h-7">
            End session
          </Button>
        </div>
      }
    </div>

  );
};
