// @ts-nocheck
import {
  useScreenShare
} from '@daily-co/daily-react';
import { useEffect, useState } from 'react';

import './Tray.css';




export default function Tray({ leaveCall }: { leaveCall: () => void }) {
  // const { toast } = useToast()
  // const callObject = useDaily();
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();

  // const [micButtonText, setMicButtonText] = useState("Mute");
  const [shareButtonText, setShareButtonText] = useState("Share Window");

  // useEffect(() => {
  //   if (shareButtonText === "Share Window") {
  //     setShareButtonText(shareButtonText === "Share Window" ? "Stop Sharing" : "Share Window");
  //     toggleScreenShare();
  //   }
  // }, []);

  // const localSessionId = useLocalSessionId();
  // const localVideo = useVideoTrack(localSessionId);
  // const localAudio = useAudioTrack(localSessionId);
  // const mutedVideo = localVideo.isOff;
  // const mutedAudio = localAudio.isOff;

  // const toggleVideo = useCallback(() => {
  //   // @ts-expect-error
  //   callObject.setLocalVideo(mutedVideo);
  // }, [callObject, mutedVideo]);

  // const toggleAudio = useCallback(() => {
  //   // @ts-expect-error
  //   callObject.setLocalAudio(mutedAudio);
  // }, [callObject, mutedAudio]);

  const toggleScreenShare = () => isSharingScreen ? stopScreenShare() : startScreenShare({
    // track constraints
    displayMediaOptions: {
      audio: false,
      selfBrowserSurface: 'include',
    },
  });

  useEffect(() => {
    startScreenShare({
      // track constraints
      displayMediaOptions: {
        audio: false,
        selfBrowserSurface: 'include',
      },
    });
  }, [])

  // const handleMicButtonClick = () => {
  //   setMicButtonText(micButtonText === "Mute" ? "Unmute" : "Mute");
  //   toggleAudio();
  //   toast({
  //     title: "Audio muted",
  //     description: "Your audio has been muted",
  //   });
  // }


  // const handleShareButtonClick = () => {
  //   setShareButtonText(shareButtonText === "Share Window" ? "Stop Sharing" : "Share Window");
  //   toggleScreenShare();
  // }

  // const handleExitButtonClick = () => {
  //   leaveCall();
  // }

  return (
    <div className="grid grid-cols-3 gap-4">
      <div></div>
      <div className="flex flex-row">
        <div className="mr-5">
          {/* <Button variant="outline" onClick={handleMicButtonClick}>
            {micButtonText}
          </Button> */}
        </div>
        {/* <div className="mr-5">
          <Button variant="outline" onClick={handleShareButtonClick}>
            {shareButtonText}
          </Button>
        </div> */}
        {/* <div className="mr-5">
          <Button variant="outline" onClick={handleExitButtonClick}>
            Exit
          </Button>
        </div> */}
      </div>
      <div></div>
    </div>
  );
}
