import { useScreenShare } from "@daily-co/daily-react";
import { Button } from "./ui/button";

/**
 * Only renders children if the user is sharing their screen.
 */
const ScreenShareBoundary = (props: {
  children: React.ReactNode
}) => {
  const { isSharingScreen } = useScreenShare();

  if (!isSharingScreen) {
    return <div className='text-center flex flex-col justify-center'>
      <p className='p-2'>
        You must share your screen to begin!
      </p>
      <Button onClick={() => {
        location.reload()
      }} variant="default" className=" self-center">
        Continue
      </Button>
    </div>
  }

  return props.children
}

export default ScreenShareBoundary