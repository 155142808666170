import { authProvider } from "@/auth";

function InstructionalLog() {
  if (!authProvider.instructionalLogUrl) {
    return (
      <div className="flex w-full justify-center p-20">
        <p>
          <strong>Oops!</strong> There is no instructional log associated with
          this user. If you believe this is an error, please email Once support
          at support@tryonce.com.
        </p>
      </div>
    );
  }

  return (
    <div className="flex w-full justify-center p-20">
      <p>
        <a
          href={authProvider.instructionalLogUrl}
          target="_blank"
          className="text-blue-700 underline text-lg"
        >
          Click here to open your Instructional Log.
        </a>
      </p>
    </div>
  );
}

export default InstructionalLog;
