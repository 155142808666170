import { useRouteError } from "react-router-dom";
import { Button } from "./ui/button";

export default function ErrorPage() {
  const error = useRouteError() as {
    statusText: number;
    message: string;
  };

  return (
    <div id="error-page" className="p-8">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <p>
        <Button onClick={() => {
          window.location.reload();
        }}>Reload</Button>
      </p>
    </div>
  );
}