import { useEffect, useState } from "react";

/**
 * Returns the current time in the user's local time zone.
 */
export default function useLocalTime(): { time: string } {
  const [time, setTime] = useState<string>(getLocalTime());

  function getLocalTime() {
    const event = new Date();
    const time = event.toLocaleTimeString("en-US");
    return time;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(getLocalTime());
    }, 1000);
    return () => clearInterval(timer);
  });

  return { time };
}
