import { Cross2Icon } from "@radix-ui/react-icons"
import { Button } from "./ui/button";

interface DialogProps {
  children: React.ReactNode;
  id: string;
  title: string;
}

export function openDialog(id: string) {
  const dialog = document.querySelector(`dialog#${id}`) as HTMLDialogElement;
  dialog?.showModal();
}

export function closeDialog(id: string) {
  const dialog = document.querySelector(`dialog#${id}`) as HTMLDialogElement;
  dialog?.close();
}

const Dialog = (props: DialogProps) => (
  <dialog id={props.id} className="p-3 rounded-md">
    <header className="p-2 flex items-center">
      <h2 className="text-lg font-semibold">{props.title}</h2>
      <Button
        onClick={() => closeDialog(props.id)}
        variant="outline"
        className="flex items-center justify-center ml-auto">
        <Cross2Icon />
      </Button>
    </header>
    <div className="p-2">
      {props.children}
    </div>
  </dialog>
);

export default Dialog;