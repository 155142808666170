import { useEffect } from "react";

function useClickOutside(enabled: boolean, ref: React.RefObject<HTMLElement>, callback: () => void, triggerRef?: React.RefObject<HTMLElement>) {
    useEffect(() => {
        function onClick(event: MouseEvent) {
            if (!enabled) {
                return;
            }
            const clickedOutsideMainElement = ref.current && !ref.current.contains(event?.target as Node);
            const clickedOnTriggerElement = triggerRef?.current && triggerRef.current.contains(event?.target as Node);
            if (clickedOutsideMainElement && !clickedOnTriggerElement) {
                callback();
                event.preventDefault();
            }
        }
        document.addEventListener("mousedown", onClick);
        return () => {
            document.removeEventListener("mousedown", onClick);
        }
    }, [ref, callback]);
}

export default useClickOutside;